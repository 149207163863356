import { defineStore } from 'pinia'
import EventService from '@/services/EventService.js'
const moment = require('moment')
var newEvent
var start
var end

export const useEventStore = defineStore('EventStore', {
  state() {
    return {
      events: [],
      createSubscription: null,
      updateSubscription: null,
      deleteSubscription: null,
    }
  },

  actions: {
    update(event) {
      EventService.update(event)
    },

    deleteEvent(id) {
      EventService.deleteEvent(id)
    },

    list(formattedDate) {
      return EventService.list(formattedDate).then((events) => {
        this.events = events
      })
    },

    subscribe() {
      this.createSubscription = EventService.onCreate((createdEvent) => {
        this.events.push(createdEvent)
      })

      this.updateSubscription = EventService.onUpdate((updatedEvent) => {
        const found = this.events.find((event) => event.id === updatedEvent.id)
        Object.assign(found, updatedEvent)
      })

      this.deleteSubscription = EventService.onDelete((deletedEvent) => {
        var events = []
        this.events.forEach((event) => {
          if (deletedEvent.id !== event.id) {
            events.push(event)
          }
        })
        this.events = events
      })
    },

    unsubscribe() {
      this.createSubscription.unsubscribe()
      this.updateSubscription.unsubscribe()
      this.deleteSubscription.unsubscribe()
    },

    add(event) {
      let number = 1
      if (parseInt(event.repeat, 10)) {
        number = parseInt(event.repeat, 10) + 1
      }
      let results = []
      let date = event.date
      for (let i = 0; i < number; i++) {
        results.push({
          name: event.name,
          start: event.start,
          end: event.end,
          date: date,
          cage: event.cage.replace(' ', ''),
          type: event.type.replace(' ', ''),
        })
        date = moment(date).add(1, 'w').format('YYYY-MM-DD')
      }
      results.forEach(function (event) {
        EventService.add(event)
      })
    },
  },
})
