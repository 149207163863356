import { createEvent, deleteEvent, updateEvent } from '@/graphql/mutations'
import {
  onCreateEvent,
  onUpdateEvent,
  onDeleteEvent,
} from '@/graphql/subscriptions'
import { listEvents, eventsByDate } from '@/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const moment = require('moment')

const cages = {
  Cage1: 'Cage 1',
  Cage2: 'Cage 2',
  Cage3: 'Cage 3',
  Cage4: 'Cage 4',
  Cage5: 'Cage 5',
  Cage6: 'Cage 6',
}

function formatEvent(event) {
  var start, end
  start = moment(event.date + ' ' + event.start, 'YYYY-MM-DD h:mma').toDate()
  end = moment(event.date + ' ' + event.end, 'YYYY-MM-DD h:mma').toDate()
  return {
    id: event.id,
    start: start,
    end: end,
    timed: true,
    category: cages[event.cage],
    name: event.name,
    type: event.type,
    date: event.date,
  }
}

function transformEvent(event) {
  return {
    id: event.id,
    start: event.start,
    end: event.end,
    cage: event.cage.replace(' ', ''),
    name: event.name,
    type: event.type.replace(' ', ''),
    date: event.date,
  }
}

export default {
  list(formattedDate) {
    return API.graphql({
      query: eventsByDate,
      variables: { date: formattedDate },
    }).then((response) => {
      return new Promise((resolve) => {
        const events = response.data.eventsByDate.items
        var results = []
        var start, end
        events.forEach(function (event) {
          results.push(formatEvent(event))
        })
        resolve(results)
      })
    })
  },

  onCreate(next) {
    return API.graphql(graphqlOperation(onCreateEvent)).subscribe({
      next: (data) => {
        next(formatEvent(data.value.data.onCreateEvent))
      },
    })
  },

  onUpdate(next) {
    return API.graphql(graphqlOperation(onUpdateEvent)).subscribe({
      next: (data) => {
        next(formatEvent(data.value.data.onUpdateEvent))
      },
    })
  },

  onDelete(next) {
    return API.graphql(graphqlOperation(onDeleteEvent)).subscribe({
      next: (data) => {
        next(data.value.data.onDeleteEvent)
      },
    })
  },

  importEvents() {
    // events.forEach(function (event) {
    //   let date = moment(event.date).format('YYYY-MM-DD')
    //   let newEvent = {
    //     start: event.start,
    //     end: event.end,
    //     cage: event.cage.replace(' ', ''),
    //     name: event.name,
    //     type: event.type.replace(' ', ''),
    //     date: date,
    //   }
    //   return API.graphql(
    //     graphqlOperation(createEvent, {
    //       input: newEvent,
    //     })
    //   ).then((response) => {})
    // })
  },

  update(event) {
    API.graphql(
      graphqlOperation(updateEvent, {
        input: transformEvent(event),
      })
    )
      .then((response) => {})
      .catch((error) => {
        console.log(error)
      })
  },

  add(event) {
    API.graphql(
      graphqlOperation(createEvent, {
        input: transformEvent(event),
      })
    )
      .then((response) => {})
      .catch((error) => {
        console.log(error)
      })
  },

  deleteEvent(id) {
    API.graphql(
      graphqlOperation(deleteEvent, {
        input: {
          id: id,
        },
      })
    )
  },
}
