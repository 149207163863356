var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500px"},on:{"click:outside":_vm.cancel},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","required":""},model:{value:(_vm.event.name),callback:function ($$v) {_vm.$set(_vm.event, "name", $$v)},expression:"event.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-select',{attrs:{"label":"Event Type","required":"","items":['Team', 'Lesson', 'Individual', 'Player Development']},model:{value:(_vm.event.type),callback:function ($$v) {_vm.$set(_vm.event, "type", $$v)},expression:"event.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":"Cage","required":"","items":[
                'Cage 1',
                'Cage 2',
                'Cage 3',
                'Cage 4',
                'Cage 5',
                'Cage 6' ]},model:{value:(_vm.event.cage),callback:function ($$v) {_vm.$set(_vm.event, "cage", $$v)},expression:"event.cage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Event Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.event.date),callback:function ($$v) {_vm.$set(_vm.event, "date", $$v)},expression:"event.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.formatDate},model:{value:(_vm.event.date),callback:function ($$v) {_vm.$set(_vm.event, "date", $$v)},expression:"event.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":"Start Time","required":"","items":_vm.hours},model:{value:(_vm.event.start),callback:function ($$v) {_vm.$set(_vm.event, "start", $$v)},expression:"event.start"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":"End Time","required":"","items":_vm.hours},model:{value:(_vm.event.end),callback:function ($$v) {_vm.$set(_vm.event, "end", $$v)},expression:"event.end"}})],1)],1),(!_vm.edit)?_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"6"}},[_c('v-text-field',{attrs:{"label":"Repeat # of Weeks"},model:{value:(_vm.event.repeat),callback:function ($$v) {_vm.$set(_vm.event, "repeat", $$v)},expression:"event.repeat"}})],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[(_vm.edit)?_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteEvent}},[_vm._v("Delete")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }