<template>
  <div>
    <!-- Cage Calendar  -->
    <v-container v-show="calendar" class="elevation-3 heavy">
      <v-row class="fill-height">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-col class="text-right" cols="2">
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="previousMonth"
              >
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>

            <v-col class="text-center" cols="8">
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
            </v-col>

            <v-col class="text-left" cols="2">
              <v-btn fab text small color="grey darken-2" @click="nextMonth">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-toolbar>
        </v-sheet>
      </v-row>

      <v-row>
        <v-col>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="currentDay"
              color="primary"
              @click:date="viewDay"
            ></v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- Cage Schedule  -->
    <v-container v-show="cageSchedule">
      <v-row class="fill-height">
        <v-col cols="12">
          <v-sheet>
            <v-toolbar flat>
              <v-btn fab text x-small color="blue" @click="viewCalendar">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
              <v-btn fab text x-small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text x-small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn
                color="blue"
                v-if="userStore.isAuthenticated"
                x-small
                outlined
                fab
                @click="addEvent"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn
                color="blue"
                :to="{ name: 'Login' }"
                v-if="!userStore.isAuthenticated"
                x-small
                outlined
                fab
              >
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <EventDialog
              v-if="showDialog"
              :edit="edit"
              :show-dialog="showDialog"
              :event="event"
              @closeDialog="closeDialog"
              @showSnackbar="showSnackbar"
            />
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-sheet>
            <v-calendar
              ref="schedule"
              v-model="currentDay"
              color="primary"
              type="category"
              category-show-all
              :categories="categories"
              :events="eventStore.events"
              first-interval="7"
              interval-count="15"
              @click:event="editEvent"
              :event-color="eventColor"
            >
            </v-calendar>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <h1>Facility Information</h1>
          <p>
            We have everything you need to run an efficent indoor practice. We
            offer 6 retactable cages that come fully equipped with:
          </p>

          <ul>
            <li>L-Screen</li>
            <li>Ball Cart</li>
            <li>Hitting Mat</li>
            <li>Baseballs or Softballs</li>
          </ul>
          <br />
          <p>
            We also have 2 portable baseball pitching mounds for players/teams
            to use.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1>Facility Rental Options</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img class="white--text align-end" height="320px" :src="Lscreen">
            </v-img>
            <v-card-text class="text--primary text-center">
              <h3>Individual Cage Rental</h3>
              <p>
                30 minutes - $20.00 <br />
                60 minutes - $40.00
              </p>
              <br />
              <ContactButton title="Contact Us" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="gymoverview"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Whole Turf Area (6 Cages)</h3>
              <p>
                90 minutes - $200.00 <br />
                (Weekends Only)
              </p>
              <br />

              <ContactButton title="Contact Us" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400" min-height="152">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="gymoverview"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Team Winter Practices</h3>
              <p>
                If your team practices regularly, lock your practice times in
                now!
              </p>
              <br />

              <ContactButton title="Winter Schedule" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="andrewmound"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Special Events</h3>
              <p>
                Contact us to host an event or birthday party at the facility!
              </p>
              <br />

              <ContactButton title="Let's Party" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <h1>Facility Rules</h1>
          <p>
            We ask all of our members and guests to treat the facility like it
            is your own. Here are some rules to remember when at the facility:
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Overall Absolutes</h4>
          <ol>
            <li>No Gum or Sunflower Seeds inside the facility.</li>
            <li>No food or drinks are allowed on the turf/gym area.</li>
            <li>
              No tobacco products of any kind are allowed in the facility.
            </li>
          </ol>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Common Curtesy</h4>
          <ol>
            <li>Please throw away all trash.</li>
            <li>Please clean off shoes before entering the facility.</li>
            <li>
              Please respect everyone and all of the equipment in the facility.
            </li>
          </ol>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Gym Rules</h4>
          <ol>
            <li>No horseplay in the gym area.</li>
            <li>Please put back all the gym equipment after use.</li>
            <li>
              No strength training is allowed without a Born Again Strength
              trainer's supervision. (Regardless of age)
            </li>
          </ol>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Turf Rules</h4>
          <ol>
            <li>Please do not hang on the nets.</li>
            <li>All athletes must be under adult supervision.</li>
            <li>
              Please put back all the baseball equipment after use (Tee, Balls,
              Ball Cart, L-Screen).
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { useEventStore } from '@/store/EventStore'
import { useUserStore } from '@/store/UserStore'
import EventDialog from '@/components/EventDialog.vue'
const moment = require('moment')
import ContactButton from '@/components/ContactButton.vue'
import gymoverview from '@/assets/images/gymoverview.jpg'
import andrewmound from '@/assets/images/andrewmound.jpg'
import Lscreen from '@/assets/images/Lscreen.jpg'

export default {
  components: { EventDialog, ContactButton },
  data: () => ({
    edit: false,
    event: {},
    currentDay: '',
    categories: ['Cage 1', 'Cage 2', 'Cage 3', 'Cage 4', 'Cage 5', 'Cage 6'],
    showDialog: false,
    cageSchedule: true,
    calendar: false,
    snackbar: false,
    text: '',
    date: new Date(),
    gymoverview: gymoverview,
    andrewmound: andrewmound,
    Lscreen: Lscreen,
  }),

  setup() {
    const eventStore = useEventStore()
    eventStore.subscribe()
    console.log('subcribe')

    const userStore = useUserStore()

    return {
      eventStore,
      userStore,
    }
  },
  created() {
    const formattedDate = moment(this.date).format('YYYY-MM-DD')
    this.eventStore.list(formattedDate)
  },
  destroyed() {
    console.log('unsubscribe')
    this.eventStore.unsubscribe()
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  methods: {
    eventColor(event) {
      if (event.type === 'Team') {
        return 'purple'
      } else if (event.type === 'Lesson') {
        return 'primary'
      } else if (event.type === 'Individual') {
        return 'red'
      } else if (event.type === 'PlayerDevelopment') {
        return 'green'
      }
      console.log(event.type)
    },

    setToday() {
      this.currentDay = ''
    },
    prev() {
      this.$refs.schedule.prev()
      this.viewDay({ date: this.currentDay })
    },
    next() {
      this.$refs.schedule.next()
      this.viewDay({ date: this.currentDay })
    },
    addEvent() {
      this.edit = false
      this.event = {}
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    editEvent(eventInfo) {
      if (!this.userStore.isAuthenticated) return

      this.edit = true
      this.event = {
        id: eventInfo.event.id,
        cage: eventInfo.event.category,
        date: eventInfo.event.date,
        end: this.$moment(eventInfo.event.end).format('h:mma'),
        start: this.$moment(eventInfo.event.start).format('h:mma'),
        name: eventInfo.event.name,
        type: eventInfo.event.type,
      }
      this.showDialog = true
    },
    viewCalendar() {
      this.cageSchedule = false
      this.calendar = true
    },
    viewDay({ date }) {
      this.eventStore.list(date).then(() => {
        this.date = this.$moment(date).toDate()
        this.cageSchedule = true
        this.calendar = false
      })
    },

    previousMonth() {
      this.$refs.calendar.prev()
    },
    nextMonth() {
      this.$refs.calendar.next()
    },
    showSnackbar(text) {
      this.text = text
      this.snackbar = true
    },
  },
}
</script>
