<template>
  <div>
    <v-dialog @click:outside="cancel" v-model="showDialog" width="500px">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="event.name"
                label="Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="8">
              <v-select
                v-model="event.type"
                label="Event Type"
                required
                :items="['Team', 'Lesson', 'Individual', 'Player Development']"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="event.cage"
                label="Cage"
                required
                :items="[
                  'Cage 1',
                  'Cage 2',
                  'Cage 3',
                  'Cage 4',
                  'Cage 5',
                  'Cage 6',
                ]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.date"
                    label="Event Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="event.date"
                  @input="formatDate"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="event.start"
                label="Start Time"
                required
                :items="hours"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="event.end"
                label="End Time"
                required
                :items="hours"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!edit">
            <v-col cols="6" offset="6">
              <v-text-field v-model="event.repeat" label="Repeat # of Weeks">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn v-if="edit" color="red" text @click="deleteEvent"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="cancel">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useEventStore } from '@/store/EventStore'

export default {
  name: 'Dialog',
  props: {
    showDialog: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    event: Object,
  },

  data() {
    return {
      startDate: false,
      hours: [
        '8:00am',
        '8:30am',
        '9:00am',
        '9:30am',
        '10:00am',
        '10:30am',
        '11:00am',
        '11:30am',
        '12:00pm',
        '12:30pm',
        '1:00pm',
        '1:30pm',
        '2:00pm',
        '2:30pm',
        '3:00pm',
        '3:30pm',
        '4:00pm',
        '4:30pm',
        '5:00pm',
        '5:30pm',
        '6:00pm',
        '6:30pm',
        '7:00pm',
        '7:30pm',
        '8:00pm',
        '8:30pm',
        '9:00pm',
      ],
    }
  },

  computed: {
    title() {
      if (this.edit === true) {
        return 'Edit Event'
      } else {
        return 'Add Event'
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    save() {
      const eventStore = useEventStore()
      if (this.edit === true) {
        eventStore.update(this.event)
      } else {
        eventStore.add(this.event)
      }
      this.closeDialog()
      this.$emit('showSnackbar', 'Event Saved')
    },
    cancel() {
      this.closeDialog()
    },
    deleteEvent() {
      const eventStore = useEventStore()
      eventStore.deleteEvent(this.event.id)
      this.closeDialog()
      this.$emit('showSnackbar', 'Event Deleted')
    },
    formatDate(date) {
      this.startDate = false
    },
  },
}
</script>

<style></style>
